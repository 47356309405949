<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">GX&PT</h2>
        </b-link>
        <b-card-title class="mb-1">
          {{ $t("Join As An Distributor User") }}
          <img
            class="biceps-icon"
            :src="require('@/assets/images/elements/biceps.png')"
            alt="" />
        </b-card-title>
        <b-card-text>
          <b>Sayın {{ firstName }} {{ lastName }} ,</b>
        </b-card-text>
        <b-card-text class="mb-2">
          <b>{{ companyName }}</b> firmasına distributor kullanıcısı olarak
          katılmayı onaylayınız.
        </b-card-text>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              <b>{{ $t("Join As An Distributor User") }}</b>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mounted } from "vue-echarts";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      required,
      email,
      personInviteId: null,
      joinType: null,
      submitValid: true,
      companyName: "",
      firstName: "",
      lastName: "",
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.submitValid) {
          this.$database.AccountService.invite_confirm_check(
            this.personInviteId
          ).then((res) => {
            if (res.IsSuccess === true || res.IsSuccess === 1) {
              this.$functions.Messages.success("You Joined As An Distributor User");
              this.$store.dispatch("auth/logout");
            } else {
              this.$store.dispatch("auth/logout");
            }
          });
        }
      });
    },
    getDistributorConfirmData() {
      this.$database.DistributorService.distributor_confirm(
        this.personInviteId
      ).then((res) => {
        if (res.IsSuccess === true || res.is_success === 1) {
          this.firstName = res.Result.FirstName;
          this.lastName = res.Result.LastName;
          this.companyName= res.Result.CompanyBrancName
        }
      });
    },
  },
  created() {
    this.personInviteId = this.$route.params.param1;
    this.joinType = this.$route.params.param2;
  },
  mounted() {
    this.getDistributorConfirmData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.biceps-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 7px;
}
</style>
